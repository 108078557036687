// import $ from "jquery"

// let $window = $(window)

const $nav = document.querySelector('nav');
const $anchors = document.querySelectorAll('[data-anchorfor]');



window.onscroll = () => {
    const navHeight = $nav.offsetHeight;
    let target = null;

    // clear
    $anchors.forEach(x => x.classList.remove('current'));

    // select current scroll target
    $anchors.forEach(el => {
        const anchorFor = el.dataset.anchorfor;
        const $target = document.querySelector(`[data-anchor='${anchorFor}']`);
        const top = $target.getBoundingClientRect().top;

        if (target == null || top <= navHeight) {
            target = el;
        }

    });

    // add
    target.classList.add('current');
}

$anchors.forEach(el => el.onclick = e => {
    const anchorFor = el.dataset.anchorfor;
    const $target = document.querySelector(`[data-anchor='${anchorFor}']`);
    const navHeight = $nav.offsetHeight;
    const dimensions = $target.getBoundingClientRect();
    console.log('clicky', dimensions, window.scrollY);
    const y = dimensions.top + window.scrollY - navHeight + 1;
    window.scrollTo(0, y);
});

const map = L.map('map', {
    center: [51.336756, 3.827401], 
    zoom: 15,
    zoomDelta: 2,
    dragging: false,
    scrollWheelZoom: false,
});

L.tileLayer('https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png', {
    attribution: 'Wikimedia maps | Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
}).addTo(map);
// L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
//     attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
// }).addTo(map);

L.marker([51.336756, 3.827401]).addTo(map)
    .bindPopup('Porgy en Bess<br>Noordstraat 52<br>4531 GJ Terneuzen')
    .openPopup();